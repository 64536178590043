<template>
  <div class="static-page">
    <div v-if="mode === 'onlyHeader' || mode === 'both'">
      <h2>Obchodní podmínky společnosti anyrent.one s.r.o. a Reklamační řád</h2>
    </div>
    <div v-if="mode === 'onlyBody' || mode === 'both'">
      <p>
        Společnost anyrent.one s.r.o., se sídlem Hládkov 920/12, Střešovice, 169 00 Praha 6, IČ: 08684936, zapsaná v&nbsp;obchodním rejstříku vedeném u Městského soudu v&nbsp;Praze, v&nbsp;oddíle C, vložka 323215 (dále jen „Poskytovatel“) tímto zveřejňuje své obchodní podmínky (dále také jen „OP“), které se uplatní na nájem zboží prezentovaného na platformě Anyrent, dostupné na internetové adrese https://anyrent.cz/ (dále jen „Stránky“ a „Platforma Anyrent“), ze strany uživatele platformy – nájemce (dále jen „Uživatel“ nebo „Nájemce“).
        <br>
        Prostřednictvím Platformy Anyrent mohou Poskytovatel a půjčovny sportovního vybavení (dále jen „Půjčovny“) v&nbsp;rámci své podnikatelské činnosti nabízet svůj inventář k&nbsp;objednání, pronájmu a rezervaci a Uživatelé mohou vyhledávat, rezervovat a pronajmout sportovní vybavení (dále jen „Zboží“).
      </p>
      <h2>Obchodní podmínky</h2>
      <ol class="list list-lvl-1">
        <li>
          <div>
            <span class="list-hglght">Úvodní ustanovení</span>
            <ol class="list list-lvl-2">
              <li>
                Nájemce současně s&nbsp;potvrzením objednávky nájmu Zboží na Stránkách akceptuje tyto OP upravující:
                <ol class="list list-lvl-3">
                  <li>
                    vzájemná práva a povinnosti stran ve vztahu vyplývajícím z&nbsp;nájmu Zboží Nájemcem od Poskytovatele nebo od Půjčovny prostřednictvím Poskytovatele, jakož i práva a povinnosti stran vztahující se k&nbsp;poskytnutí služeb s&nbsp;nájmem Zboží souvisejících (např. servis)
                  </li>
                  <li>
                    vzájemná práva a povinnosti stran vyplývající z&nbsp;dopravy Zboží ze strany Poskytovatele
                  </li>
                  <li>
                    vzájemná práva a povinnosti vyplývající z&nbsp;užívání Platformy Anyrent.
                  </li>
                </ol>
              </li>
              <li>
                Využití Platformy Anyrent není zpoplatněno.
              </li>
              <li>
                V&nbsp; závislosti na tom, zda pronajímá Zboží Poskytovatel či Půjčovna vstupuje Nájemce akceptací těchto OP do nájemního vztahu s&nbsp;Poskytovatelem nebo s&nbsp;Půjčovnou. Nájemce bere na vědomí, že vstupuje-li do nájemního vztahu s&nbsp;Půjčovnou, je Půjčovna zastoupena Poskytovatelem a nájemní smlouvu s&nbsp;Nájemcem uzavírá Půjčovna prostřednictvím Poskytovatele. Poskytovatel je v&nbsp;nájemním vztahu s&nbsp;Nájemcem v&nbsp;pozici pronajímatele nebo v&nbsp;pozici zprostředkovatele nájemní smlouvy a případně smlouvy o poskytnutí služby (servisu) mezi Půjčovnou a Nájemcem. Při zajišťování dopravy Zboží a užívání Platformy Anyrent je Nájemce ve smluvním vztahu s&nbsp;Poskytovatelem.
              </li>
              <li>
                Vystupuje-li Poskytovatel v&nbsp;pozici zprostředkovatele, zveřejňuje na Platformě Anyrent informace poskytnuté mu jednotlivými Půjčovnami, Poskytovatel proto nemůže zaručit, a tedy nést odpovědnost za to, že všechny informace jsou přesné, správné a úplné. Odpovědnost za přesnost, správnost a úplnost informací nese Půjčovna.
              </li>
              <li>
                Tyto OP jsou určeny výhradně pro nájem Zboží Nájemcem – spotřebitelem, tedy osobou, která při uzavírání nájemní smlouvy jedná mimo rámec své podnikatelské činnosti nebo mimo rámec samostatného výkonu svého povolání. Totéž platí i při využití servisu, dopravy Anyrent a při užívání Platformy Anyrent. Nájemce není oprávněn Zboží, které je předmětem nájemní smlouvy využít v&nbsp;rámci své podnikatelské činnosti.
              </li>
              <li>
                Právní vztahy mezi Poskytovatelem a Nájemcem a mezi Poskytovatelem, Půjčovnou a Nájemcem se řídí těmito OP, zákonem č. 89/2012 Sb., občanský zákoník, ve znění pozdějších předpisů a zákonem č. 634/1992 Sb., o ochraně spotřebitele, ve znění pozdějších předpisů.
              </li>
              <li>
                Do Platformy Anyrent jsou zahrnuty pouze Půjčovny, se kterými má Poskytovatel smluvní vztah. Právní vztahy mezi Poskytovatelem a Půjčovnami, se kterými Poskytovatel Nájemci zprostředkovává uzavření nájemní smlouvy, se řídí smluvními podmínkami uzavíranými jednotlivě s&nbsp;každou Půjčovnou.
              </li>
            </ol>
          </div>
        </li>
        <li>
          <div>
            <span class="list-hglght">Informační povinnost, výklad pojmů</span>
            <ol class="list list-lvl-2">
              <li>
                Poskytovatel tímto informuje Nájemce a Nájemce potvrzením objednávky nájmu Zboží na Stránkách stvrzuje, že byl Poskytovatelem informován o:
                <ol class="list list-lvl-3">
                  <li>
                    totožnosti Poskytovatele, kterým je společnost&nbsp;anyrent.one s.r.o. se sídlem Hládkov 920/12, Střešovice, 169 00 Praha 6, IČ: 08684936, zapsaná v&nbsp;obchodním rejstříku vedeném u Městského soudu v&nbsp;Praze, v&nbsp;oddíle C, vložka 323215. Telefonní čísla, adresa pro doručování elektronické pošty a další kontaktní údaje jsou uvedeny na Stránkách. Kontaktním místem Poskytovatele je jeho provozovna na adrese: Anyrent, Císařská louka 162, Praha 5.&nbsp;Totožnost konkrétní Půjčovny je uvedena v&nbsp;potvrzení objednávky dle bodu 3.8. těchto OP.
                  </li>
                  <li>
                    tom, že označení Zboží a popis jeho hlavních vlastností je uveden na Stránkách jednotlivě u každého Zboží, popřípadě odkazem na internetové stránky třetích stran (Půjčovny, výrobce, distributora apod.).
                  </li>
                  <li>
                    výši nájemného, případně způsobu jeho výpočtu. Výše nájemného je vždy uvedena u Zboží na Stránkách a jeho výše je konečná. Nájemné je uváděno včetně DPH, všech daní a poplatků.
                  </li>
                  <li>
                    tom, že má povinnost zaplatit Poskytovateli zálohu na nájemné a to nejpozději současně s&nbsp;uzavřením nájemní smlouvy. Poskytovatel, resp.Půjčovna si vyhrazuje právo zálohu na nájemné na některé položky Zboží nevyžadovat.
                  </li>
                  <li>
                    tom, že povinnost složit kauci a její výše je uvedena jednotlivě u každého Zboží na Stránkách. Poskytovatel, resp. Půjčovna si vyhrazuje právo kauci na některé položky Zboží nevyžadovat.  Nájemce je povinen složit kauci nejpozději v&nbsp;den převzetí Zboží do nájmu.
                  </li>
                  <li>
                    tom, že v&nbsp;souvislosti s&nbsp;nájmem Zboží je možné u vybraných položek Zboží objednat u Poskytovatele nebo u Půjčovny prostřednictvím Poskytovatele servis Zboží, jehož specifikace je uvedena na Stránkách jednotlivě u každého Zboží, popř. odkazem na internetové stránky Půjčovny. Není-li servis u konkrétní položky Zboží poskytován, není možnost volby servisu u této položky na Stránkách uváděna.
                  </li>
                  <li>
                    výši ceny za servis Zboží, případně způsobu jejího výpočtu. Výše ceny servisu je vždy uvedena u Zboží na Stránkách a její výše je konečná. Cena servisu je uváděna včetně DPH, všech daní a poplatků.
                  </li>
                  <li>
                    způsobu dodání a vrácení Zboží, který je vždy uveden s&nbsp;možností výběru Nájemce na Stránkách. Nájemce je oprávněn zvolit si jiný způsob dodání Zboží a jiný způsob vrácení Zboží, není-li v&nbsp;těchto OP uvedeno jinak. Zvolený způsob dodání a vrácení Zboží již následně nelze bez souhlasu Poskytovatele měnit.
                  </li>
                  <li>
                    nákladech na dodání a vrácení&nbsp;Zboží, tedy o ceně dopravy, která je vždy uvedena s&nbsp;možností výběru Nájemce na Stránkách. Volbou příslušného způsobu dodání a vrácení Zboží Nájemce zároveň souhlasí s&nbsp;cenou dopravy, a zavazuje se ji uhradit.
                  </li>
                  <li>
                    způsobu platby, přičemž způsob platby je vždy uveden s&nbsp;možností výběru Nájemce na Stránkách. Zvolený způsob platby již následně nelze bez souhlasu Poskytovatele měnit. Některé způsoby platby mohou podléhat poplatku, jehož výše je vždy uvedena na Stránkách a Nájemce volbou příslušného způsobu platby souhlasí s&nbsp;výší poplatku a zavazuje se jej uhradit. Nájemce bere na vědomí, že kauci je možné u Poskytovatele hradit pouze bezhotovostně, tedy kartou při převzetí Zboží od dopravce nebo v&nbsp;provozovně Poskytovatele/provozovně Půjčovny v&nbsp;závislosti na volbě způsobu dodání Zboží. Nájemce zároveň bere na vědomí, že pokud mu vznikne povinnost uhradit zálohu na nájemném je povinen ji uhradit nejpozději současně s&nbsp;uzavřením nájemní smlouvy, z&nbsp;toho důvodu není možné zálohu na nájemném uhradit jinak než online platbou při uzavírání nájemní smlouvy.
                  </li>
                  <li>
                    právech Nájemce vznikajících z&nbsp;vadného plnění a podmínkách pro jejich uplatňování. Práva z&nbsp;vadného plnění a podmínky pro jejich uplatnění jsou uvedeny v&nbsp;čl. IX těchto OP.
                  </li>
                  <li>
                    době trvání závazku. Smlouva je vždy uzavírána na dobu určitou a závazky z&nbsp;ní zanikají jejím splněním, tj. uplynutím doby nájmu/zajištěním dopravy/poskytnutím servisu a vypořádáním všech práv a povinností ze&nbsp;smlouvy vyplývajících.
                  </li>
                  <li>
                    ochraně osobních údajů. Zásady ochrany osobních údajů Poskytovatele jsou uvedeny na Stránkách. Zásady ochrany osobních údajů Půjčoven jsou uvedeny na internetových stránkách každé jednotlivé Půjčovny.
                  </li>
                  <li>
                    tom, že Poskytovatel uchovává v&nbsp;digitální a chráněné podobě obsah všech smluv uzavřených Nájemcem prostřednictvím Stránek, tedy jak smluv, kde Poskytovatel vystupuje jako smluvní strana, tak i smluv, kde je Poskytovatel v&nbsp;pozici zprostředkovatele smlouvy. Digitální obsah smluv&nbsp;a dalších případných údajů uchovávaných Poskytovatelem je slučitelný&nbsp;běžně používaným hardwarem (tj. desktopovými zařízeními na platformách PC a Mac) a softwarem (tj. běžně používanými kancelářskými systémy, zejména Microsoft Office, Acrobat reader a běžné webové/internetové formáty). Poskytovatel Nájemci umožní na jeho žádost přístup k&nbsp;uzavřené smlouvě, tedy poskytne Nájemci úplné znění uzavřené smlouvy v&nbsp;elektronické podobě.
                  </li>
                  <li>
                    nákladech na prostředky komunikace na dálku. Užití platformy Anyrent a přístup na Stránky není zpoplatněn. Náklady na internetové připojení Nájemce nese Nájemce.
                  </li>
                  <li>
                    právu odstoupit od smlouvy uzavřené prostřednictvím prostředků komunikace na dálku, tedy prostřednictvím Stránek. Nájemce má právo odstoupit od smlouvy za podmínek uvedených v&nbsp;čl. VIII&nbsp;těchto OP.
                  </li>
                  <li>
                    tom, že smlouvu lze uzavřít pouze v&nbsp;českém jazyce.
                  </li>
                  <li>
                    krocích vedoucích k&nbsp;uzavření smlouvy. &nbsp;Způsob a postupy vedoucí k&nbsp;uzavření smlouvy jsou uvedeny v&nbsp;čl. III těchto OP. V&nbsp;čl. III OP jsou rovněž uvedeny možnosti Nájemce ke zjištění a opravě chyb vzniklých při zadávání dat před potvrzením objednávky.
                  </li>
                  <li>
                    kodexech chování. Poskytovatel zveřejní na Stránkách kodexy chování, které jsou pro něj závazné nebo které dobrovolně dodržuje; v&nbsp;případě že Stránky kodex chování neobsahují, není žádný kodex chování pro Poskytovatele závazný.
                  </li>
                  <li>
                    tom, že pokud se z&nbsp;jakéhokoliv důvodu ujednání těchto OP odchylují od zákonných ustanovení na ochranu spotřebitele, pak se k&nbsp;ustanovením, které jsou v&nbsp;rozporu s&nbsp;právními předpisy na ochranu spotřebitele nepřihlíží. To platí i v&nbsp;případě, že se Nájemce vzdá zvláštního práva, které mu zákon poskytuje.
                  </li>
                </ol>
              </li>
              <li>
                V&nbsp;těchto OP mají následující pojmy níže uvedený význam:
                <ol class="list list-lvl-3">
                  <li>
                    Zbožím se rozumí movitá věc, která má být předmětem nájmu.
                  </li>
                  <li>
                    Pronajímatelem je Poskytovatel nebo Půjčovna.
                  </li>
                  <li>
                    Kaucí se rozumí vratné finanční prostředky složené Nájemcem nejpozději v&nbsp;den převzetí Zboží do nájmu zajišťující nároky Poskytovatele/Půjčovny z&nbsp;uzavřené nájemní smlouvy (dále jen „Kauce“). Poskytovatel a/nebo Půjčovna jsou oprávněni jednostranně započítat svoje nároky uvedené v&nbsp;těchto OP oproti Kauci, to vše za podmínek uvedených dále v&nbsp;těchto OP.
                  </li>
                  <li>
                    Zálohou se rozumí záloha ve stanovené výši části nájemného zaplacená Nájemcem při uzavření smlouvy, která bude započtena na úhradu nájemného, není-li v&nbsp;těchto OP stanoveno jinak (dále jen „Záloha“).
                  </li>
                  <li>
                    Doba nájmu je doba, po kterou je Nájemce oprávněn dočasně užívat Zboží.
                  </li>
                  <li>
                    Servisem je služba související s&nbsp;nájmem Zboží poskytovaná Pronajímatelem na základě objednávky Nájemce. Servis je poskytován výhradně na Zboží, které je předmětem nájemní smlouvy. Servis je poskytován vždy před převzetím Zboží Nájemcem do dočasného užívání. Servis Zboží zajišťuje vždy Pronajímatel, se kterým je uzavírána nájemní smlouva na konkrétní Zboží, k&nbsp;němuž se servis vztahuje. Jedná se např. o seřízení lyží či kola (dále jen „Servis“).
                  </li>
                </ol>
              </li>
            </ol>
          </div>
        </li>
        <li>
          <span class="list-hglght">Uzavření smlouvy</span>
          <ol class="list list-lvl-2">
            <li>
              Nájemce má možnost uzavřít prostřednictvím Stránek smlouvu o nájmu Zboží a současně s&nbsp;ní může být na základě volby Nájemce uzavřena i:
              <ol class="list list-lvl-3">
                <li>
                  smlouva o poskytnutí služby – Servisu
                </li>
                <li>
                  zasílatelská smlouva – smlouva o dopravě Zboží
                </li>
              </ol>
            </li>
            <li>
              Není možné uzavřít Smlouvu s&nbsp;více Pronajímateli v&nbsp;rámci jedné objednávky.
            </li>
            <li>
              Jestliže je Smlouva uzavírána s&nbsp;Půjčovnou, je vždy uzavírána prostřednictvím Poskytovatele. Zasílatelská smlouva je uzavírána vždy pouze s&nbsp;Poskytovatelem, který zajišťuje dopravu Zboží.
            </li>
            <li>
              Uzavírá-li Nájemce nájemní smlouvu a spolu s&nbsp;ní i smlouvu o poskytnutí služby a/nebo zasílatelskou smlouvu činí tak jednou objednávkou.
            </li>
            <li>
              Nájemce, který chce uzavřít Smlouvu, je povinen na Stránkách po výběru Zboží, které má být předmětem nájmu, vyplnit objednávkový formulář obsahující údaje týkající se specifikace a množství Zboží, Servisu, doby nájmu, určení způsobu platby, určení způsobu dodání a vrácení Zboží, identifikační a kontaktní údaje Nájemce a případně další potřebné údaje dle aktuálního objednávkového formuláře.
            </li>
            <li>
              Součástí objednávky je i uvedení výše nájemného, výše Zálohy, výše Kauce, cena dopravy a popř. cena Servisu.
            </li>
            <li>
              Nájemce je povinen vyplněné údaje před konečným potvrzením objednávky nájmu Zboží zkontrolovat a vyplněné údaje případně opravit, a to stejným způsobem, jakým byly tyto údaje zadány. Nájemce odpovídá za to, že údaje v&nbsp;objednávce jsou správné, úplné a aktuální a nese veškerá rizika spojená s&nbsp;tím, jaké údaje v&nbsp;objednávce zadal. Poskytovatel nenese odpovědnost za případnou škodu či újmu způsobenou nesprávností, neaktuálností či neúplností údajů obsažených v&nbsp;objednávce. Po potvrzení objednávky zadané údaje již nelze měnit. Potvrzením objednávky ze strany Nájemce došlo k&nbsp;uzavření Smlouvy a objednávka je závazná.
            </li>
            <li>
              Pokud je vyžadována Záloha, je Nájemce povinen současně s&nbsp;uzavřením Smlouvy zaplatit Zálohu. Nedojde-li k&nbsp;úhradě Zálohy v&nbsp;případech, kdy je vyžadována, právní účinky Smlouvy zanikají.
            </li>
            <li>
              Poskytovatel potvrdí Nájemci prostřednictvím informativní e-mailové zprávy zaslané na e-mailovou adresu uvedenou Nájemcem přijetí objednávky. V&nbsp;e-mailu bude uvedena totožnost Pronajímatele. Na uzavření Smlouvy však toto potvrzení nemá vliv.
            </li>
            <li>
              Poskytovatel je v&nbsp;závislosti na charakteru objednávky, která není obvyklá (zejména s&nbsp;ohledem na množství Zboží, výši nájemného, cenu dopravy, apod.) oprávněn žádat Nájemce o autorizaci objednávky jiným vhodným způsobem než jejím potvrzením na Stránkách, např. telefonicky či písemně. Nedojde-li na základě žádosti Poskytovatele ze strany Nájemce k&nbsp;autorizaci objednávky nejpozději do 24 hodin od žádosti Poskytovatele, právní účinky Smlouvy uplynutím této lhůty pominou. Poskytovatel v&nbsp;takovém případě vrátí Nájemci v&nbsp;plné výši všechna od něho přijatá finanční plnění, a to nejpozději do 5 pracovních dnů od zániku Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak.
            </li>
            <li>
              Poskytovatel umožňuje v&nbsp;oprávněných případech také uzavření Smlouvy prostřednictvím jiných prostředků komunikace na dálku než prostřednictvím Stránek, tj. zejména prostřednictvím e-mailové nebo telefonické komunikace s&nbsp;Poskytovatelem. V&nbsp;těchto případech dochází k&nbsp;uzavření Smlouvy až okamžikem, kdy:
              <ol class="list list-lvl-3">
                <li>
                  Nájemce obdrží od Poskytovatele prostřednictvím e-mailové korespondence shrnutí veškerých podstatných náležitostí Smlouvy uvedených v&nbsp;bodě 3.4. a 3.5. těchto OP, a zároveň
                </li>
                <li>
                  Nájemce do 24 hodin&nbsp;od obdržení výše uvedeného shrnutí Smlouvy prostřednictvím e-mailové zprávy nebo prostřednictvím kontaktního formuláře s&nbsp;uvedením případného čísla objednávky či jiného Poskytovatelem určeného údaje pro identifikaci objednávky objednávku akceptuje.
                </li>
              </ol>
            </li>
            <li>
              Poskytovatel neodpovídá za to, že Nájemce neobdržel příslušnou elektronickou komunikaci.
            </li>
            <li>
              Poskytovatel je oprávněn používat technické/digitální prostředky ochraňující před automatickým (nikoli lidským) vyplňováním objednávkového formuláře.
            </li>
            <li>
              Předmětem Smlouvy jsou pouze položky Zboží výslovně uvedené ve Smlouvě, nedohodnou-li se smluvní strany jinak.
            </li>
            <li>
              Nájemce bere na vědomí, že s&nbsp;ohledem na realizaci nájmu Zboží prostřednictvím Stránek a současně také v&nbsp;jednotlivých Půjčovnách, a s&nbsp;ohledem na aktualizaci příslušných údajů na Stránkách, se může stát,&nbsp;že Nájemcem objednané Zboží již není dostupné, a to i přestože na Stránkách je doposud jako dostupné uvedeno. V&nbsp;takovém případě bude Poskytovatel bezodkladně poté, co zjistí nedostupnost Zboží, Nájemce informovat. Nedohodne-li se Nájemce s&nbsp;Poskytovatelem ani do 5 pracovních dnů od uzavření Smlouvy jinak (např. na změně doby nájmu či jiné změně objednávky), právní účinky Smlouvy automaticky zanikají a žádná ze smluvních stran není oprávněna po druhé smluvní straně požadovat žádnou náhradu škody ani újmy. Poskytovatel v&nbsp;takovém případě vrátí Nájemci v&nbsp;plné výši všechna od něho přijatá finanční plnění, a to nejpozději do 5 pracovních dnů od zániku Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak.
            </li>
            <li>
              Zjevné chyby a nepřesnosti ve Smlouvě včetně překlepů nejsou závazné.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Práva a povinnosti smluvních stran</span>
          <ol class="list list-lvl-2">
            <li>
              Poskytovatel se zavazuje, že Nájemci pronajme sám nebo že jeho prostřednictvím bude Nájemci pronajato Zboží specifikované ve Smlouvě. Pronajímatel se zavazuje, že Zboží bude v&nbsp;dobrém stavu a funkční.
            </li>
            <li>
              Poskytovatel je povinen Nájemci na jeho žádost vydat doklad o nájmu Zboží s&nbsp;uvedením doby trvání nájmu, popisem Zboží, výší nájemného, a to spolu s&nbsp;identifikačními údaji Pronajímatele. Tuto povinnost Poskytovatel splní zasláním kopie objednávky.
            </li>
            <li>
              Nájemce je povinen užívat Zboží v&nbsp;souladu s&nbsp;pravidly pro zacházení se Zbožím a v&nbsp;souladu s&nbsp;jeho obvyklým užitím.
            </li>
            <li>
              Nájemce není oprávněn na Zboží provádět jakékoliv změny, úpravy či opravy bez souhlasu Poskytovatele.
            </li>
            <li>
              Nájemce neodpovídá za opotřebení Zboží způsobené jeho řádným užíváním.
            </li>
            <li>
              Nájemce odpovídá Pronajímateli za snížení hodnoty Zboží, které vzniklo v&nbsp;důsledku nakládání se Zbožím jinak, než v&nbsp;souladu s&nbsp;jeho určením a pravidly užívání. Pronajímatel (Půjčovna rovněž prostřednictvím Poskytovatele) je oprávněn uplatnit nárok na náhradu škody způsobené snížením hodnoty Zboží a tento svůj nárok započítat oproti Kauci.
            </li>
            <li>
              Nájemce odpovídá Pronajímateli za poškození Zboží. Pronajímatel (v případě Půjčovny rovněž prostřednictvím Poskytovatele) je oprávněn uplatnit nárok na náhradu škody způsobené poškozením Zboží a tento svůj nárok započítat oproti Kauci.
            </li>
            <li>
              Nájemce je povinen chránit Zboží před ztrátou a zničením. Nájemce je povinen nahradit Pronajímateli škodu způsobenou ztrátou či zničením Zboží. Nájemce je povinen oznámit Poskytovateli nebo Pronajímateli ztrátu nebo zničení Zboží bez zbytečného odkladu. Nájemce hradí nájemné až do okamžiku oznámení ztráty či zničení Zboží. Pronajímatel (v případě Půjčovny rovněž prostřednictvím Poskytovatele) je oprávněn započítat svůj nárok na náhradu škody způsobené ztrátou nebo zničením Zboží oproti Kauci i oproti nároku Nájemce na vrácení části nájemného.
            </li>
            <li>
              Nájemce není oprávněn Zboží prodat, pronajmout, zcizit nebo zatížit právy třetích osob. Nájemce se nestává vlastníkem Zboží.
            </li>
            <li>
              Po dobu trvání nájmu nese případné provozní náklady Zboží Nájemce.
            </li>
            <li>
              Pokud si Nájemce objednal rovněž Servis Zboží, je povinen poskytnout Pronajímateli nezbytnou součinnost k&nbsp;poskytnutí služby (zejména sdělit Pronajímateli potřebné údaje). Nájemce nese odpovědnost za poskytnutí správných, úplných a přesných údajů vyžadovaných pro poskytnutí Servisu. Půjčovna ani Poskytovatel nenesou odpovědnost za Servis provedený vadně v&nbsp;důsledku poskytnutí nesprávných, neúplných nebo nepřesných údajů. Půjčovna ani Poskytovatel nenesou odpovědnost za škody vzniklé v&nbsp;důsledku Servisu provedeného na základě nesprávných, neúplných nebo nepřesných údajů.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Způsob dodání zboží, převzetí a vrácení zboží</span>
          <ol class="list list-lvl-2">
            <li>
              Místo předání Zboží do Nájmu a místo vrácení Zboží Pronajímateli je místo určené Nájemcem při objednávce nájmu Zboží.
            </li>
            <li>
              Zboží je dodáváno prostřednictvím Nájemcem zvoleného způsobu dodání:
              <ol class="list list-lvl-3">
                <li>
                  osobní vyzvednutí Nájemcem v&nbsp;provozovně Poskytovatele,
                </li>
                <li>
                  osobní vyzvednutí Nájemcem v&nbsp;provozovně Půjčovny,
                </li>
                <li>
                  dodání Zboží s&nbsp;využitím dopravy Anyrent. Podmínky dopravy Zboží jsou uvedeny v&nbsp;čl. VII těchto OP.
                </li>
              </ol>
              Způsoby vrácení Zboží jsou totožné se způsoby dodání Zboží.
              Nájemce je oprávněn zvolit rozdílný způsob dodání a vrácení Zboží, s&nbsp;výjimkou případu, kdy zvolí způsob dodání Zboží dle bodu 5.2.2. OP, v&nbsp;takovém případě musí být způsob dodání i vrácení Zboží totožný.
            </li>
            <li>
              Pronajímatel je povinen přenechat Zboží Nájemci k&nbsp;dočasnému užívání po dobu trvání nájmu. Nájemce je oprávněn Zboží užívat po sjednanou dobu nájmu. Ustanovení bodu 5.4. OP na tuto skutečnost nemá vliv.
            </li>
            <li>
              Nájemce je oprávněn převzít a/nebo vrátit Zboží prostřednictvím třetí osoby. Vystupuje-li tato třetí osoba jako osoba jednající za Nájemce, je Poskytovatel/Půjčovna oprávněn jí Zboží předat nebo od ní Zboží převzít. Na odpovědnost a závazky vyplývající Nájemci ze Smlouvy to však nemá žádný vliv.
            </li>
            <li>
              Nájemce je oprávněn převzít pronajaté Zboží k&nbsp;dočasnému užíváním nejdříve ve 12 hodin v&nbsp;den předcházející prvnímu dni sjednané doby nájmu a je povinen pronajaté Zboží vrátit nejpozději ve 12 hodin v&nbsp;den následující po uplynutí doby nájmu. Nájemné se hradí pouze za dobu trvání Nájmu, tedy za dobu, o kterou Nájemce převzal Zboží dřív/vrátil později v&nbsp;souladu s&nbsp;tímto ustanovením OP se nájemné neplatí.
            </li>
            <li>
              Nájemce je povinen Zboží převzít k&nbsp;dočasnému užívání nejpozději v&nbsp;den počátku nájmu. Nepřevezme-li Nájemce Zboží nejpozději v&nbsp;den počátku nájmu, Smlouva pozbývá účinnosti. Poskytovatel v&nbsp;takovém případě vrátí Nájemci veškeré od něho přijaté finanční prostředky snížené o Zálohu, a to nejpozději do 5 pracovních dnů od zániku Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak.
            </li>
            <li>
              Je-li vyžadována Kauce, je Nájemce povinen ji složit nejpozději při převzetí Zboží k&nbsp;dočasnému užívání. Výše Kauce je uvedena ve Smlouvě. Nesloží-li Nájemce Kauci, právní účinky Smlouvy zanikají. Poskytovatel v&nbsp;takovém případě vrátí Nájemci veškeré od něho přijaté finanční prostředky snížené o Zálohu, a to nejpozději do 5 pracovních dnů od zániku Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak.
            </li>
            <li>
              Je-li to obvyklé Poskytovatel nebo Půjčovna při předání Zboží Nájemci předvedou, že je Zboží funkční. Poskytovatel nebo Půjčovna seznámí Nájemce s&nbsp;pravidly pro zacházení se Zbožím, jinak mu Poskytovatel nebo Půjčovna předá pokyny v&nbsp;písemné formě.
            </li>
            <li>
              Nájemce je povinen si Zboží při převzetí řádně a důkladně zkontrolovat a prohlédnout. V&nbsp;případě, že Nájemce shledá na Zboží vadu nebo shledá jiný rozpor se Smlouvou, je Nájemce povinen toto bezodkladně oznámit Poskytovateli způsobem uvedeným v&nbsp;čl. IX těchto OP.
            </li>
            <li>
              Nájemce nese nebezpečí škody na Zboží od převzetí Zboží do jeho vrácení.
            </li>
            <li>
              Nájemce je povinen vrátit Zboží ve stejném stavu, v&nbsp;jakém jej převzal, funkční a nepoškozené. V&nbsp;opačném případě se aplikují body 4.6. až 4.8. OP, tedy Pronajímatel je oprávněn uplatit vůči Nájemci své nároky na náhradu škody a tyto započítat oproti Kauci.
            </li>
            <li>
              Nájemce je povinen vrátit Zboží očištěné, v&nbsp;opačném případě je Pronajímatel oprávněn Nájemci účtovat poplatek za vyčištění Zboží a tento započíst oproti Kauci (Půjčovna tak může učinit rovněž prostřednictvím Poskytovatele).
            </li>
            <li>
              Nájemce je povinen vrátit Zboží nejpozději v&nbsp;termínu uvedeném v&nbsp;bodě 5.5. OP. Nevrátí-li Nájemce Zboží jím zvoleným způsobem nejpozději ve 12 hodin v&nbsp;den následující po uplynutí doby nájmu, je Nájemce povinen uhradit vedle nájemného za sjednanou dobu trvání nájmu také smluvní pokutu odpovídající výši nájemného za dobu prodlení s&nbsp;vrácením Zboží. Pronajímatel je vedle smluvní pokuty oprávněn po Nájemci požadovat náhradu škody způsobené prodlením Nájemce s&nbsp;vrácením Zboží, a to v&nbsp;plné výši. Pronajímatel (v případě Půjčovny rovněž prostřednictvím Poskytovatele) je oprávněn započítat své nároky na smluvní pokutu a na náhradu škody oproti Kauci. Toto ustanovení OP se nepoužije pro Hodinové výpůjčky.
            </li>
            <li>
              Nemůže-li být Zboží předáno Nájemci ve&nbsp;sjednaných termínech, oznámí Poskytovatel tuto skutečnost bez zbytečného odkladu Nájemci. Nedohodne-li Nájemce s&nbsp;Poskytovatelem ani do 5 pracovních dnů od oznámení Poskytovatele jinak (např. na prodloužení doby nájmu či jiné změně objednávky), právní účinky Smlouvy automaticky zanikají a žádná ze smluvních stran není oprávněna po druhé smluvní straně požadovat žádnou náhradu škody ani újmy. Poskytovatel v&nbsp;takovém případě vrátí Nájemci všechna od něho přijatá finanční plnění v&nbsp;plné výši, a to nejpozději do 5 pracovních dnů od zániku Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak.
            </li>
            <li>
              Kauce bude vrácena Nájemci do 5 pracovních dnů po vrácení Zboží nebo do 5 pracovních dnů od oznámení Nájemce, že došlo ke ztrátě nebo zničení Zboží. Kauce bude vrácena stejným způsobem, jakým byla přijata, nedohodne-li se Poskytovatel s&nbsp;Nájemcem jinak. Kauce bude vrácena ve výši odpovídající rozdílu Nájemcem složené výše Kauce po odečtení (započtení) případných nároků Pronajímatele. Nepokryje-li Kauce nároky Pronajímatele v&nbsp;plné výši, nárok Nájemce na vrácení Kauce zanikne započtením a Pronajímatel je oprávněn vymáhat zbylou výši svých nároků po Nájemci soudní cestou.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Nájemné a další platby, platební podmínky, způsoby platby</span>
          <ol class="list list-lvl-2">
            <li>
              Nájemce je povinen za dočasné užívání Zboží uhradit nájemné ve výši sjednané ve Smlouvě. Nájemce je vedle nájemného povinen uhradit případně i cenu Servisu, cenu dopravy a poplatek za způsob platby. Nájemce je rovněž povinen uhradit Zálohu a Kauci v&nbsp;souladu s&nbsp;těmito OP.
            </li>
            <li>
              Nájemné uvedené na Stránkách je s&nbsp;výjimkou zřejmé chyby v&nbsp;jeho výši závazné v&nbsp;okamžiku uzavření Smlouvy. Nájemné uvedené ve&nbsp;Smlouvě je konečné. V&nbsp;případě zřejmé chyby ve výši nájemného je Pronajímatel (Půjčovna rovněž prostřednictvím Poskytovatele) oprávněn bez dalšího jednostranně odstoupit od Smlouvy. Pronajímatel si vyhrazuje právo změny výše nájemného, a to kdykoliv před uzavřením Smlouvy.
            </li>
            <li>
              Nájemné (s&nbsp;vyčíslením případné Zálohy), cena Servisu, cena dopravy, poplatek za způsob platby jsou uváděny jako samostatné položky a jsou uváděny včetně DPH, veškerých daní a poplatků není-li na Stránkách a ve Smlouvě výslovně uvedeno jinak.
            </li>
            <li>
              Nájemné, cenu Servisu, cenu dopravy a poplatky spojené se zvoleným způsobem platby je možné uhradit následujícími způsoby:
              <ol class="list list-lvl-3">
                <li>
                  online platbou prostřednictvím Poskytovatelem používaného platebního systému (například platba kartou - 3D secure)
                </li>
                <li>
                  v&nbsp;hotovosti nebo platební kartou při převzetí Zboží k&nbsp;dočasnému užívání v&nbsp;provozovně Poskytovatele
                </li>
                <li>
                  v&nbsp;hotovosti nebo platební kartou při převzetí Zboží k&nbsp;dočasnému užívání v&nbsp;provozovně Půjčovny
                </li>
                <li>
                  platební kartou řidiči při převzetí Zboží k&nbsp;dočasnému užívání, zvolil-li Nájemce jako způsob dodání Zboží dopravu Anyrent.
                </li>
              </ol>
              Nájemce není oprávněn pro úhradu nájemného, ceny Servisu, ceny dopravy a poplatků spojených se zvoleným způsobem platby zvolit rozdílné způsoby platby.
              <br>
              Pokud Nájemce zvolil jako způsob dodání Zboží osobní vyzvednutí v&nbsp;provozovně Půjčovny dle bodu 5.2.2. OP je povinen úhradu nájemného, ceny Servisu a poplatků spojených se zvoleným způsobem platby uhradit v&nbsp;hotovosti nebo platební kartou v&nbsp;provozovně Půjčovny dle bodu 6.4.3. OP.
              <br>
              Kauci je možné uhradit pouze platební kartou při převzetí Zboží k&nbsp;dočasnému užívání v&nbsp;provozovně Poskytovatele, Půjčovny nebo způsobem uvedeným v&nbsp;bodě 6.4.4. OP výše, v&nbsp;závislosti na zvoleném způsobu dodání Zboží. Zálohu, je-li dle těchto OP vyžadována, je možné zaplatit pouze způsobem uvedeným v&nbsp;bodě 6.4.1. OP výše.
            </li>
            <li>
              Pokud Nájemce zvolí jako způsob platby nájemného způsob uvedený v&nbsp;bodě 6.4.2., 6.4.3. nebo 6.4.4. OP výše bude již uhrazená Záloha započtena na nájemné a Nájemce tak uhradí pouze rozdíl mezi celkovou výší nájemného a uhrazenou Zálohou.
            </li>
            <li>
              Všechny platby jsou považovány za uhrazené:
              <ol class="list list-lvl-3">
                <li>
                  připsáním celé jejich výše na účet Poskytovatele
                </li>
                <li>
                  připsáním celé jejich výše na účet Půjčovny v&nbsp;případě úhrady platební kartou v&nbsp;provozovně Půjčovny
                </li>
                <li>
                  jejich uhrazením v&nbsp;hotovosti v&nbsp;provozovně Poskytovatele nebo Půjčovny.
                </li>
              </ol>
              Veškeré platby jsou hrazeny Poskytovateli nebo Půjčovně prostřednictvím Poskytovatele, s&nbsp;výjimkou způsobu platby dle bodu 6.4.3. OP, kdy jsou hrazeny Půjčovně.
            </li>
            <li>
              Nájemce odsouhlasením těchto OP souhlasí s&nbsp;tím, že mu daňový doklad (faktura) bude zaslán v&nbsp;elektronické podobě na jím uvedenou e-mailovou adresu.
            </li>
            <li>
              Za den uskutečnění zdanitelného plnění se považuje den vystavení daňového dokladu, není-li příslušným právním předpisem stanoveno jinak.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Doprava Anyrent</span>
          <ol class="list list-lvl-2">
            <li>
              Volbou způsobu dodání Zboží s&nbsp;využitím dopravy Anyrent uzavírá Nájemce s&nbsp;Poskytovatelem zasílatelskou
              smlouvu. Poskytovatel se zavazuje obstarat pro Nájemce vlastním jménem a na vlastní účet přepravu Zboží
              z&nbsp;provozovny Poskytovatele a/nebo Půjčovny na adresu určenou Nájemcem ve Smlouvě a/nebo přepravu Zboží
              z&nbsp;adresy určené Nájemcem do provozovny Poskytovatele a/nebo Půjčovny. Nájemce je povinen uhradit cenu
              dopravy.
            </li>
            <li>
              Přeprava Zboží bude uskutečněna v&nbsp;termínech sjednaných ve Smlouvě. Ustanovení 5.5. OP se použije
              obdobně. Nájemce je povinen ve sjednaný termín zajistit převzetí nebo předání přepravovaného Zboží od
              řidiče nebo řidiči Poskytovatele.
            </li>
            <li>
              Při využití dopravy Anyrent se Zboží považuje za dodané okamžikem jeho převzetí Nájemcem od řidiče
              Poskytovatele a za vrácené okamžikem jeho předání řidiči Poskytovatele. Ustanovení bodu 7.4. OP na tuto
              skutečnost nemá žádný vliv.
            </li>
            <li>
              Poskytovatel je oprávněn předat nebo převzít Zboží třetí osobě nebo od třetí osoby, která vystupuje
              jako osoba jednající za Nájemce na jím určené adrese. Na odpovědnost a závazky vyplývající Nájemci ze
              Smlouvy to však nemá žádný vliv. Poskytovatel neodpovídá za to, že Nájemce neobdržel Zboží, bylo-li
              doručováno na adresu uvedenou Nájemcem ve Smlouvě, avšak na adrese určené Nájemcem Zboží převzala nebo
              odmítla převzít třetí osoba vystupující za Nájemce. V&nbsp;takovém případě bude Poskytovatel postupovat vůči
              Nájemci v&nbsp;souladu s&nbsp;těmito OP, zejména s&nbsp;ustanovením bodu 7.6. OP níže.
            </li>
            <li>
              Nájemce je povinen uhradit cenu dopravy i v&nbsp;případě, že nezajistí převzetí nebo předání Zboží řidiči
              Poskytovatele ve sjednaném termínu.
            </li>
            <li>
              Nezajistí-li Nájemce převzetí Zboží od řidiče Poskytovatele ve sjednaném termínu, Smlouva pozbývá
              účinnosti. Poskytovatel v&nbsp;takovém případě vrátí Nájemci veškeré od něho přijaté finanční prostředky
              snížené o Zálohu a cenu dopravy (cenu za dodání Zboží), a to nejpozději do 5 pracovních dnů od zániku
              Smlouvy. Finanční prostředky budou Nájemci vráceny stejným způsobem, jakým byly přijaty, nedohodne-li se
              Poskytovatel s&nbsp;Nájemcem jinak. Bod 5.6. OP se v&nbsp;tomto případě nepoužije.
            </li>
            <li>
              Nezajistí-li Nájemce předání Zboží řidiči Poskytovatele ve sjednaném termínu, jedná se o porušení
              povinnosti Nájemce vrátit Zboží a vůči Nájemci bude postupováno v&nbsp;souladu s&nbsp;těmito OP a právními předpisy.
            </li>
            <li>
              Doprava Anyrent nezahrnuje službu vynesení/snesení Zboží. Zboží bude dopraveno k&nbsp;prvním uzamykatelným
              dveřím na adrese určené Nájemcem a na stejném místě bude rovněž převzato k&nbsp;dopravě (vráceno).
            </li>
            <li>Poskytovatel je oprávněn dopravu realizovat prostřednictvím svých subdodavatelů.</li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Odstoupení od smlouvy</span>
          <ol class="list list-lvl-2">
            <li>
              Nájemce má právo ve lhůtě 14 dnů od uzavření Smlouvy od Smlouvy uzavřené prostřednictvím Stránek
              odstoupit i bez udání důvodu. Uzavřel-li Nájemce v&nbsp;souladu s&nbsp;těmito OP více Smluv, je oprávněn odstoupit
              od všech Smluv i od kterékoli z&nbsp;nich. Nájemce je také oprávněn odstoupit pouze od části Smlouvy, tedy
              pouze od vybraného Zboží. Odstoupí-li Nájemce od nájemní smlouvy, pak smlouvy související (tj. smlouva o
              poskytnutí Servisu a zasílatelská smlouva vztahující se ke Zboží, které je předmětem odstoupení) pozbývají
              právní účinky, pokud od nich nebylo rovněž odstoupeno.
            </li>
            <li>
              Poskytovatel žádá Nájemce, aby pro odstoupení od Smlouvy využívali především formulář pro odstoupení od
              Smlouvy dostupný na Stránkách. Využití formuláře umožní Poskytovateli efektivněji vyřídit nároky Nájemce a
              zároveň je pro Nájemce uživatelsky nejpříjemnější.
            </li>
            <li>
              Nevyužije-li Nájemce formulář pro odstoupení od Smlouvy, může oznámení o odstoupení od Smlouvy:
              <ol class="list list-lvl-3">
                <li>odeslat na e-mailovou na adresu Poskytovatele info@anyrent.cz</li>
                <li>odeslat na adresu sídla Poskytovatele</li>
                <li>doručit Půjčovně na její e-mailovou adresu nebo na adresu jejího sídla či provozovny ve vztahu ke Zboží pronajatému touto Půjčovnou.</li>
              </ol>
              Odstupuje-li Nájemce od zasílatelské smlouvy musí být oznámení doručeno Poskytovateli.
              <br>
              V&nbsp;oznámení o odstoupení od Smlouvy dle bodu 8.3.1. nebo 8.3.2. výše Nájemce vždy uvede: číslo objednávky, datum uzavření Smlouvy, upřesnění, od kterých Smluv Nájemce odstupuje (např. všech, od smlouvy na poskytnutí Servisu, od nájemní smlouvy apod.) a zda odstupuje od celé Smlouvy či zda se odstoupení vztahuje pouze na vybrané Zboží.
            </li>
            <li>
              Odstoupení od Smlouvy může být adresováno Poskytovateli, a to i v&nbsp;případech, kdy je Pronajímatelem
              Půjčovna. V&nbsp;takovém případě je odstoupení realizováno vůči Půjčovně prostřednictvím Poskytovatele.
              Poskytovatel se zavazuje Půjčovnu o odstoupení Nájemce od Smlouvy bezodkladně informovat.
            </li>
            <li>
              Pronajímatel (v případě Půjčovny rovněž prostřednictvím Poskytovatele) potvrdí Nájemci přijetí oznámení
              o odstoupení od Smlouvy.
            </li>
            <li>
              14ti denní lhůta pro odstoupení od Smlouvy je dodržena v&nbsp;případě, že Nájemce odeslal oznámení o
              odstoupení od Smlouvy před jejím uplynutím.
            </li>
            <li>
              Pokud Nájemce již převzal Zboží a odstoupil od Smlouvy do 14 dnů od jejího uzavření, je povinen vrátit
              Pronajímateli (v případě Půjčovny rovněž prostřednictvím Poskytovatele) Zboží bez zbytečného odkladu,
              nejpozději však do 14 dnů od odstoupení od Smlouvy. Pokud doba nájmu uplyne před uplynutím 14ti denní
              lhůty, je povinen Nájemce vrátit Zboží nejpozději v&nbsp;poslední den sjednané doby nájmu. Zároveň je Nájemce
              povinen uhradit nájemné až do okamžiku vrácení Zboží.
            </li>
            <li>
              Pokud pronajaté Zboží nelze vrátit z&nbsp;důvodu jeho ztráty nebo zničení, použije se bod 4.8. OP. Pokud je
              vrácené Zboží poškozené nebo došlo ke snížení jeho hodnoty, použijí se body 4.6. a 4.7. OP. Pronajímatel
              (Půjčovna rovněž prostřednictvím Poskytovatele) je oprávněn v&nbsp;těchto případech započítat svoje nároky na
              náhradu škody jak oproti Kauci, tak oproti nároku Nájemce na vrácení nájemného.
            </li>
            <li>
              Pronajímatel (Půjčovna rovněž prostřednictvím Poskytovatele) vrátí Nájemci všechna od něho přijatá
              finanční plnění v&nbsp;plné výši nebo snížené o nájemné, které je Nájemce povinen uhradit dle bodu 8.7. OP, a
              to stejným způsobem, kterým je přijal, nedohodnou-li se Pronajímatel (Půjčovna rovněž prostřednictvím
              Poskytovatele) s&nbsp;Nájemcem jinak. Toto neplatí pro dodatečné náklady vzniklé v&nbsp;důsledku Nájemcem zvoleného
              způsobu dodání, který je jiný než Poskytovatelem nabízený nejlevnější způsob dodání. Peněžní prostředky
              budou vráceny Nájemci bez zbytečného odkladu, nejpozději do 14 dnů od vrácení Zboží, pokud jej Nájemce již
              převzal. Pokud Nájemce Zboží nepřevzal budou peněžní prostředky vráceny nejpozději do 14 dnů od odeslání
              oznámení o odstoupení od Smlouvy.
            </li>
            <li>
              Odstoupí-li Nájemce od Smlouvy nese veškeré náklady spojené s&nbsp;odstoupením od Smlouvy, zejména náklady
              na vrácení Zboží.
            </li>
            <li>
              Nájemce není oprávněn odstoupit od nájemní smlouvy, jestliže doba nájmu uplynula před uplynutím lhůty
              pro odstoupení od Smlouvy. Nájemce rovněž není oprávněn odstoupit od zasílatelské smlouvy, jestliže byla
              doprava poskytnuta před uplynutím lhůty pro odstoupení od Smlouvy. Nájemce dále není oprávněn odstoupit od
              smlouvy o poskytnutí Servisu, jestliže byl Servis poskytnut před uplynutím lhůty pro odstoupení od
              Smlouvy.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Odpovědnost za vady</span>
          <ol class="list list-lvl-3">
            <li>
              Pronajímatel odpovídá Nájemci, že Zboží při převzetí do dočasného užívání nemá vady, pro které jej
              nelze řádně užívat nebo vady, pro které jej lze užívat jen se značnými obtížemi (dále jen „Vada“).
            </li>
            <li>Má-li Zboží Vadu, má Nájemce právo, aby mu bylo poskytnuto jiné Zboží sloužící témuž účelu.</li>
            <li>
              Po dobu, po niž nemohl Nájemce Zboží řádně užívat vůbec nebo se značnými obtížemi, má Nájemce právo na
              prominutí nájemného nebo na slevu na nájemném. Toto právo je Nájemce povinen uplatnit nejpozději do
              uplynutí doby nájmu, jinak toto právo zaniká.
            </li>
            <li>
              Nájemce je povinen oznámit, že Zboží má Vady bez zbytečného odkladu poté, co Vady zjistil. Pronajímatel
              neodpovídá za Vadu nebo zvětšení rozsahu Vady v&nbsp;případě, že Nájemce po zjištění Vady nebo poté, co se Vada
              stala zjevnou, Zboží dále používal. Nájemce je povinen hradit nájemné v&nbsp;plné výši, a to až do oznámení
              Vady.
            </li>
            <li>
              Práva uvedená v&nbsp;tomto článku OP Nájemci nenáleží, pokud Nájemce před převzetím Zboží věděl, že Zboží má
              Vadu, anebo pokud Nájemce Vadu sám způsobil.
            </li>
            <li>
              Nároky z&nbsp;Vad je Nájemce oprávněn uplatnit jak vůči Poskytovateli, tak vůči Půjčovně (vůči Půjčovně však
              pouze v&nbsp;případech, kdy je Půjčovna Pronajímatelem). Poskytovatel z&nbsp;důvodu efektivity žádá Nájemce, aby
              přednostně uplatňovali své nároky vůči němu, a to i v&nbsp;případech, kdy je Pronajímatelem Půjčovna.
              V&nbsp;takových případech jsou Vady uplatňovány vůči Půjčovně prostřednictvím Poskytovatele. Poskytovatel se
              zavazuje Půjčovnu o uplatnění nároků z&nbsp;Vad bezodkladně informovat.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Práva duševního vlastnictví</span>
          <ol class="list list-lvl-3">
            <li>
              Práva duševního vlastnictví (včetně autorských práv) k&nbsp;Platformě Anyrent, včetně software používaného
              v&nbsp;rámci Platformy Anyrent náležejí Poskytovateli. Pořizovatelem databáze je Poskytovatel.
            </li>
            <li>
              Zakazuje se užívat, kopírovat, monitorovat, zobrazovat a stahovat obsah Platformy Anyrent za účelem
              jeho komerčního či konkurenčního využití.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Informace o mimosoudním řešení spotřebitelských sporů</span>
          <ol class="list list-lvl-3">
            <li>
              Případné spory, které vzniknou mezi Nájemcem a Poskytovatelem se Poskytovatel snaží řádně projednat a
              vyřešit k&nbsp;oboustranné spokojenosti.
            </li>
            <li>
              V&nbsp;případě, že dojde mezi Nájemcem a Pronajímatelem ke vzniku sporu ze Smlouvy (dále jen „spotřebitelský
              spor“), který se nepodaří vyřešit vzájemnou dohodou, je Nájemce oprávněn podat návrh na mimosoudní řešení
              spotřebitelského sporu subjektu určenému pro mimosoudní řešení spotřebitelských sporů. Tímto subjektem je
              Česká obchodní inspekce, Ústřední inspektorát – oddělení ADR, Štěpánská 15, 120 00 Praha 2, e-mail:
              adr@coi.cz, internetová adresa: adr.coi.cz.
            </li>
            <li>
              Nájemce rovněž může využít platformu pro řešení spotřebitelských sporů online, která je zřízena
              Evropskou komisí na adrese: http://ec.europa.eu/consumers/odr/.
            </li>
          </ol>
        </li>
        <li>
          <span class="list-hglght">Závěrečná ustanovení</span>
          <ol class="list list-lvl-3">
            <li>Poskytovatel neodpovídá za škody ani újmu způsobené nedostupností platformy Anyrent.</li>
            <li>
              Poskytovatel neodpovídá za škodu, újmu či ztrátu, která Nájemci vznikne v&nbsp;důsledku jednání či opomenutí
              Půjčovny nebo třetích osob. Poskytovatel neodpovídá ani za škodu, újmu či ztrátu, která Nájemci vznikne v
              důsledku jednání či opomenutí Nájemce, které bylo učiněno v&nbsp;rozporu s&nbsp;OP.
            </li>
            <li>K řešení sporů vzniklých z&nbsp;těchto OP jsou příslušné obecné soudy České republiky.</li>
            <li>Tyto OP jsou pro Nájemce závazné ve znění uvedeném na Stránkách ke dni uzavření Smlouvy.</li>
            <li>
              Poskytovatel je oprávněn tyto OP dle svého uvážení jednostranně měnit s&nbsp;tím, že změna OP bude vždy
              zveřejněna na Stránkách, na Stránkách tedy budou vždy dostupné aktuální OP. Poskytovatel na Stránkách
              rovněž zveřejňuje všechny verze OP.
            </li>
          </ol>
        </li>
      </ol>
      <span>Toto znění OP je platné a účinné od 1. 6. 2021</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TermsAndConditions',
  props: {
    mode: {
      type: String,
      default: 'both'
    }
  }
}
</script>

<style lang="scss" scoped>
  .list-hglght {
    font-family: 'TTCommons-DemiBold', sans-serif;
    text-transform: uppercase;
    text-align: left;
    margin-top: 1rem;
    margin-bottom: .5rem;
    display: block;
  }
  .list-lvl-1 {
    margin-left: 5px;
    padding-left: 0;
    list-style: decimal;
  }
  .list-lvl-2 {
    padding-left: 0.4rem;
    list-style: decimal;
  }
  .list-lvl-3 {
    padding-left: 0.8rem;
    list-style: decimal;
  }

  ol {
  list-style-type: none;
  counter-reset: item;
  margin: 0;
  padding: 0;
  }
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.5em;
  }
  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.5em;
  }
  li ol > li {
    margin: 0;
  }
  li ol > li:before {
    content: counters(item, ".") " ";
  }

</style>
